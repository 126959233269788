<template>
  <div class="playlist">
    <div class="new-campaign">
      <div class="container">
        <div class="header-wrap">
          <h2>{{ $t("schedule.newTitle") }}</h2>
        </div>

        <div class="creation-wrap" style="max-width: 600px">
          <form @submit="create_schedule" class="form" method="post">
            <div class="form-wrap">
              <label for="name">{{ $t("global.name") }}</label>
              <input v-model="schedule.name" class="form-input" type="name" name="name" id="name" />
            </div>

            <p style="margin-bottom: 0; text-align: right">
              <input type="submit" :value="$t('global.createNow')" class="button button-ci button-round" />
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  name: "new_schedule",
  data() {
    return {
      errors: [],
      loading: true,
      schedule: {},
    };
  },
  methods: {
    create_schedule: function (e) {
      e.preventDefault();

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/schedules",
          {
            name: this.schedule.name,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("schedule.created"),
          });
          this.$router.push("/schedules");
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
  },
  mounted() {},
};
</script>
